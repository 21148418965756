import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "../styles/footer.css"

const Footer = () => (
  <footer id="footer">
    <span style={{ flex: 1 }}>
      © {new Date().getFullYear()},{` `}
      Danny Fonseca - Propulsed by <a href="https://agence-ohana.fr/">Ohana</a>
    </span>
    <span style={{ flex: 1, textAlign: "right", textDecoration: "none" }}>
      <a
        style={{ padding: "1vw", textDecoration: "none" }}
        href="tel:(0033) 6.83.08.73.12"
      >
        <i class="fas fa-phone" /> (0033) 6.83.08.73.12
      </a>
      <a style={{ padding: "1vw" }} href="mailto:fonsiefilms@gmail.com">
        <i class="fas fa-envelope" />
      </a>
      <a
        style={{ padding: "1vw" }}
        href="https://www.facebook.com/mgscbi1"
        target="__blank"
      >
        <i class="fab fa-facebook-f" />
      </a>
      <a
        style={{ padding: "1vw" }}
        href="https://www.linkedin.com/in/danny-fonseca-433317102/"
        target="__blank"
      >
        <i class="fab fa-linkedin-in" />
      </a>
    </span>
  </footer>
)

export default Footer
