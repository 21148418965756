import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Showreel = () => (
  <Layout>
    <SEO title="showreel" />
    <iframe
      title="showreel"
      src="https://player.vimeo.com/video/257732920?byline=false&portrait=false&title=false"
      webkitallowfullscreen=""
      mozallowfullscreen=""
      allowfullscreen=""
      style={{ height: "70vh", width: "100%" }}
      width="640"
      height="360"
      frameborder="0"
    />
  </Layout>
)

export default Showreel
