/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <body
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          padding: 0,
          margin: 0,
        }}
      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: 0,
            margin: 0,
          }}
        >
          <main style={{ flex: 1 }}>{children}</main>
        </div>
        <Footer />
      </body>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
